<template>
  <div>
    <!--    步骤条区域-->
    <org-apply-steps :activeStep="stepTwo"></org-apply-steps>
    <div class="submit-area">
      <!--表单区域-->
      <div class="form-box">
        <el-form ref="orgForm" :model="orgDataForm" :rules="orgDataFormRules">
          <!--      主体信息-->
          <div class="entity-info">主体信息</div>
          <el-form-item prop="LicenseName">
            <el-input
              placeholder="请输入营业执照全称"
              v-model="orgDataForm.LicenseName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              placeholder="请输入统一社会信用代码"
              v-model="orgDataForm.code"
            ></el-input>
          </el-form-item>
          <el-form-item class="upload-item">
            <el-upload
              ref="upload"
              :action="uploadUrl"
              list-type="picture-card"
              :auto-upload="true"
              :class="{ hide: hideUpload }"
              :on-change="handleChange"
              :before-upload="beforeAvatarUpload"
              :on-success="successUp"
              :on-error="errorUp"
              :file-list="fileList"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  v-if="file.raw.type === 'application/pdf'"
                  src="~@/assets/pc/images/login/login-wechat-code.png"
                  alt=""
                />
                <img
                  v-else
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <el-progress
                  type="circle"
                  :percentage="file.percentage.toFixed(2)"
                  width="118"
                  v-if="file.percentage < 100"
                  >></el-progress
                >
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>

            <div class="upload-text">
              <span>请上传营业执照</span>
              <span>最大支持10M，支持jpg/png/pdf格式</span>
            </div>
          </el-form-item>
          <!--      申请人新信息-->
          <div class="entity-info" style="margin-top: 50px">申请人信息</div>
          <el-form-item prop="applicantName">
            <el-input
              placeholder="请输入申请人姓名"
              v-model="orgDataForm.applicantName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="applicantPhone">
            <el-input
              placeholder="请输入申请人电话"
              v-model="orgDataForm.applicantPhone"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!--  提交按钮-->
      <div class="submit-box">
        <button class="submit-btn" @click="goToNext">确认无误，提交</button>
      </div>
      <!--      -->
      <!--      图片预览区域-->
      <el-dialog :visible.sync="dialogVisibleIMG">
        <img width="100%" :src="imageUrl" alt="" />
      </el-dialog>
      <!--      pdf预览区域-->
      <el-dialog
        title="营业执照预览"
        :visible.sync="dialogVisiblePDF"
        width="90%"
      >
        <!--        分页区域-->
        <div class="tools">
          <button @click.stop="clock">顺时针</button>
          <button @click.stop="counterClock" class="mr10">逆时针</button>
        </div>
        <pdf-t
          v-for="i in numPages"
          :key="i"
          :src="url"
          :page="i"
          :rotate="pageRotate"
        >
        </pdf-t>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisiblePDF = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import orgApplySteps from "./orgApplySteps";
import pdfT from "vue-pdf";
import { request } from "@/network";
export default {
  name: "orgApplySubmit",
  components: {
    orgApplySteps,
    pdfT,
  },
  data() {
    return {
      orgDataForm: {
        url: "",
        memberId: "",
        LicenseName: "",
        code: "",
        applicantName: "",
        applicantPhone: "",
      },
      orgDataFormRules: {
        LicenseName: [
          { required: true, message: "请输入营业执照全称", trigger: "blur" },
        ],
        code: [
          {
            required: true,
            pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        applicantName: [
          { required: true, message: "请输入申请人姓名", trigger: "blur" },
        ],
        applicantPhone: [
          {
            required: true,
            pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
            message: "请输入正确的11位手机号码",
            trigger: "blur",
          },
        ],
      },
      imageUrl: "",
      stepTwo: 1,
      disabledUpload: false,
      dialogVisibleIMG: false,
      hideUpload: false,
      fileList: [],
      url: "",
      dialogVisiblePDF: false,
      numPages: 1,
      pageRotate: 0,
    };
  },
  filters: {
    percent(val) {
      return Number.parseInt(val) + "%";
    },
  },
  methods: {
    handlePreview(file) {
      this.imageUrl = file.url;
      if (file.raw.type !== "application/pdf") {
        this.dialogVisibleIMG = true;
      } else {
        this.dialogVisiblePDF = true;
        const testPDF = pdfT.createLoadingTask(this.imageUrl);
        this.url = testPDF;
        this.url.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
      }
    },
    handleChange(file, fileList) {
      this.hideUpload = fileList.length >= 1;
      this.fileList = fileList;
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        ["application/pdf", "image/png", "image/jpeg"].indexOf(file.type) === -1
      ) {
        this.$message.error("请上传jpg/png/pdf格式的文件");
        return false;
      } else if (!isLt10M) {
        this.$message.error("文件大小不能超过10M");
        return false;
      }
      return true;
    },
    handleRemove(file) {
      const upload = this.fileList;
      upload.splice(0, 1);
    },
    async goToNext() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/index/organizationApply",
        data: this.orgDataForm,
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.stepTwo = 2;
      this.$router.replace("/orgsubmitsuccess");
    },
    clock() {
      this.pageRotate += 90;
    },
    counterClock() {
      this.pageRotate -= 90;
    },
    successUp(response, file, fileList) {
      this.$message.success("文件上传成功！");
    },
    errorUp(file) {
      this.$message.error("文件上传失败！");
    },
  },
  computed:{
     uploadUrl() {
      return `${process.env.VUE_APP_BASEURL}/pcp/index/applyUploadFile`;
    },
  },
  watch: {
    fileList(newVal, oldVal) {
      if (newVal.length < 1) {
        this.hideUpload = false;
      }
    },
  },
};
</script>

<style scoped>
.submit-area {
  padding: 50px 345px 0;
}
.form-box .entity-info {
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #000000;
  border-bottom: 2px solid #000000;
}
/deep/.el-input__inner {
  padding-left: 12px;
  height: 50px;
  font-size: 16px;
}
.el-form-item {
  margin-bottom: 20px;
}

.upload-text {
  position: relative;
  height: 120px;
  width: 280px;
}
.upload-text span {
  line-height: 1;
}
.upload-text span:nth-child(1) {
  position: absolute;
  top: 38px;
  left: 20px;
  font-size: 16px;
  color: #585858;
}
.upload-text span:nth-child(2) {
  position: absolute;
  top: 68px;
  left: 20px;
  font-size: 14px;
  color: #cacaca;
}
/deep/.el-form-item__content {
  display: flex;
}
.upload-item {
  height: 120px !important;
}
.submit-btn {
  width: 160px;
  height: 46px;
  background: #156ED0;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.submit-box {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}
/*上传区域样式*/
/deep/.el-upload--picture-card {
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.hide {
  width: 120px;
  height: 120px;
}
.hide img {
  width: 120px;
  height: 120px;
}
.el-upload-list__item-actions {
  width: 120px;
  height: 120px;
}
/deep/.el-upload-list__item {
  width: 120px;
  height: 120px;
  /*去掉上传时，上传框的动画*/
  transition: none !important;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/*进度条样式*/
/deep/.el-progress {
  position: absolute;
  left: 54%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
